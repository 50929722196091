@import "../../styles/uitilities.scss";

.subdate{
    display: flex;
    background-color: #fff;
    box-shadow: 0 4px 16px 0 rgba($color: #000000, $alpha: .1);
    padding: 16px 8px;
    max-width: 284px;
    margin-top: 30px;
    border-radius: 5px;
    margin-bottom: 30px;
    @include min(992){
        margin-top: 32px;
        margin-bottom: 0;
    }
    .box{
        border-right: 1px solid #DFDFDF;
        padding: 0 28px;
        padding-bottom: 10px;
        &:last-child{
            border: none;
        }
    }
    .title{
        font-size: 14px;
        margin-bottom: 2px;
        font-weight: 700;
    }
    p{
        font-size: 14px;
        color: #484848;
        margin-top: 5px;
        font-weight: 400;
        margin: 0;
    }
}

.subdate.rtl{
    margin-left: auto;
    .box{
       
        direction:rtl;
        // border: none;
        margin-left: auto;
        p{
           
        }
        &:last-child{
            // border-: 1px solid #DFDFDF;
        }
    }
   
   
}