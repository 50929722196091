@import "../../styles/uitilities.scss";


.register{
   padding-top: 15px;
    padding-bottom: 43px;
    @include min(992){
        padding-top: 45px;
    }
    .outer_wrap{
        display: flex;
        flex-wrap: wrap;
        @include min(992){
            flex-wrap: nowrap;
        }
    }
    .reg_head{
        max-width: 670px;
    }
    .sm_link{
        display: block;
        margin-top: 20px;
        color: #0058A3;
        font-size: 14px;
        @include min(992){
            display: none;
        }
    }
    .title{
        font-size: 18px;
        margin-bottom: 5px;
        @include min(768) {
            font-size: 30px;
            margin-bottom: 10px;
      
        }
        
        @include min(1200) {
            font-size: 36px;
           
        }
    }
    .subtitle{
        margin-bottom: 20px;
        font-size: 14px;
        @include min(768){
            font-size: 18px;
            margin-bottom: 20px;   
        }
        @include min(992){
            font-size: 24px;
        }
    }
    p{
        
    }
    figure{
        margin-top: 26px;
    }
}

.rtl{
    .outer_wrap{
    
        flex-direction: row-reverse;
        .title,.subtitle,p{
            transform: rotateY(180deg);
        }
    }
}