@import "../../styles/uitilities.scss";

.register_form{
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-top: 25px;
    max-width: 900px;
  
    @include min(992){
        margin-top: 12%;
        margin-left: 50px;
    }
    :global{
        .custom_verify_btn{
            width: 100%;
      
            @include min(768){
                max-width: 327px;
    
            }
            @include min(992){
                max-width: 327px;
                margin-left: auto;
                margin-top: 20px;
            }
        }
    }
    .btn_wrap{
        width: 100%;
      
        @include min(992){
            text-align: right;
        }
     }
}

.register_form_wrap{
    display: flex;
    flex-wrap: wrap;
   column-gap: 20px;
    :global{
    
        .form-group{
            width: 100%;
            @include min(768){
                max-width: 48%; 
                margin-bottom: 34px; 
            }
            @include min(1200){
                max-width: 410px; 
            }
            .form-label{
                display: none;
                @include min(768){
                    display: block;
                }
            }
            .form-control{
                &::placeholder{
                    opacity: 1;
                    @include min(768){
                       
                        opacity: 0;
                    }
                }
            }
         }
         .form-control{
          border: 1px solid #929292;
          max-width: 410px;
         }
         .form-check{
            margin-right: 65px;
        }
        .form-check label::after{
            background-color: #CCCCCC;
            opacity: 1;
            width: 16px;
            height: 16px;
            left: 4px;
        }
        .form-check label::before{
            border-color: #CCCCCC;
        }
        .form-check input[type=radio]:checked + label::after{
            background-color: #003E72;
        }
        .form-check input[type=radio]:checked + label::before{
            border-color: #003E72;
        }
    }
    .radio_wrap{
        margin-bottom: 17px;
        width: 100%;
        .store_location{
            padding-bottom: 0;
        }
        @include min(992){
         margin-bottom: 30px;
        }
    }
    .radio_btn{
        display: flex;
        width: 100%;
     
    }
    .store_location{
        padding-bottom: 20px;
    
        @include min(992){

        }
        :global{
            .form-label{
                width: 100%;
                margin-bottom: 15px;
            }
            .form-check{
                display: flex;
                align-items: center;
                padding-left: 24px;
                .form-check-input{
                    box-shadow: none;
                    border: 1px solid #003E72;
                    width: 24px;
                    height: 24px;
                    &:checked{
                        background-color: #003E72;
                    }
                }
                label{
                    padding-left: 19px;
                    &:before,&:after{
                        display: none;
                    }
                }
            }
        }
    }
    .radio_wrap{
        width: 100%;
        margin-bottom: 17px;
        @include min(992){
         margin-bottom: 30px;
        }
        .store_location{
            margin-bottom: 0;
            padding-bottom: 0;
        }
    }


}


.register_form.rtl{
    margin-left: 0;
    @include min(992){
        margin-right: 50px;
    }
    :global{
        .custom_verify_btn{
            margin-left: 0;
            transform: rotateY(180deg);
        }
        .form-check-input{
            transform: rotateY(180deg); 
        }
    }
    .register_form_wrap{
        transform: rotateY(180deg);
    }
    .btn_wrap{
     
        @include min(992){
            text-align: left!important;
        }
    }
}