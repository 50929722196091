@import "../../styles/uitilities.scss";

.subscription {
    .title {
        font-size: 18px;
        margin-bottom: 12px;
        max-width: 320px;
        @include min(768) {
            font-size: 30px;
            max-width: 520px;
        }

        @include min(1200) {
            font-size: 36px;
            max-width: 621px;
        }
    }

    .subtitle {

        margin-bottom: 0;
        max-width: 600px;

        @include max(991) {
            font-size: 16px;
            font-weight: 400;
            margin-bottom: 16px;
        }
        @include max(767){
            font-size: 14px;
        }

    }

}