@import "../../styles/uitilities.scss";


.modal{
    padding: 12px!important;
    :global{
        .modal-header{
            padding-bottom: 40px;
            .btn-close{
                top: 25px;
                right: 25px;
                box-shadow: none;
            }
        }
        .modal-dialog{
            max-width: 850px;
        }
    }
}