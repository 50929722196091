@import "../../styles/uitilities.scss";

.subscription {
    .title {
        font-size: 18px;
        margin-bottom: 12px;
        @include min(768) {
            font-size: 30px;
        }

        @include min(1200) {
            font-size: 45px;
        }
    }

    img {
        margin-right: 10px;
        // padding-top: 7px;
        @include min(992){
            padding-top: 7px;
        } 

    }

    .subtitle {
 
        margin-bottom: 0;

        @include max(991) {
           

        }

        @include max(767) {
            font-size: 14px;
         
            max-width: 274px;
        }
    }

}

.subscription.rtl {
   
    direction: rtl;
    text-align: right;
    ul {
        direction: ltr;
        max-width: max-content;
        margin-right: auto;
    }
}